@use 'styles/variables' as v;
@use 'styles/mixins' as m;
@use '@material/textfield';
@use '@material/checkbox';

#subscription-cancel-membership {
    --cds-primary-color: rgba(49, 15, 162, 1);
        
    --md-filled-text-field-container-color: rgba(242, 242, 242, 1);
    --md-filled-text-field-container-hover-color: rgba(242, 242, 242, 1);
    --md-filled-field-hover-leading-content-color: rgba(242, 242, 242, 1);
    --md-filled-text-field-input-text-size: 14px;
    --md-filled-text-field-label-text-color: rgba(133, 133, 133, 1);
    --md-filled-text-field-focus-label-text-color: rgba(133, 133, 133, 1);
    --md-filled-text-field-label-text-size: 14px;
    --md-filled-field-supporting-text-color: rgba(107, 107, 107, 1);
    --md-filled-field-supporting-text-size: 12px;
    --md-filled-field-active-indicator-color: rgba(242, 242, 242, 1);
    --md-filled-field-hover-active-indicator-color: rgba(242, 242, 242, 1);
    --md-filled-field-hover-content-color: rgba(242, 242, 242, 1);
    --md-filled-field-supporting-text-trailing-space: 0;
    --cds-text-field-height: 84px;

    .main-container-subscriptions {
        padding: v.$regularPad;

        @media (width <= v.$desktop) {
            max-width: unset;
            padding: 0;
            background-color: v.$transparent;
            box-shadow: none;
        }
    }
    
    cds-back-button {
        position: relative;
        z-index: 10;
    }

    .cancel-membership-header {
        margin-block: v.$regularPad;

        &__link {
            line-height: 19px;
            letter-spacing: 0.1px;
            margin-block: 25px 0;    
            color: v.$purple-text;        
        }

        @media (width >= v.$desktop) {
            margin-block: v.$bigPad v.$mediumPad;
        }
    }

    .cancel-membership {
        &-container-center {
            @include m.column-gap(v.$regularPad);
            line-height: 25px;

            @media (width >= v.$phone) {
                gap: v.$regularPad;
            }

            & .cancel-membership-header__desc {
                letter-spacing: 0.25px;
            }
        }

        &-icon {
            margin-right: v.$smallPadPlus;
            height: 22px;

            @media (width >= v.$desktop) {
                height: 30px;
            }
        }

        &-buttons {            
            @include m.flex-column();   
            width: 100%;         

            @media (width >= v.$phone) {
                flex-direction: row;
                width: unset;
            }

            &__btn {
                height: 50px;
                width: 100%;

                @media (width >= v.$phone) {
                    width: 250px;              
                }
            }
        }

        &__input {
            padding-bottom: v.$bigPad;

            @media (width >= v.$phone) {
                max-width: 452px;
            }
        }

        &__checkboxes {
            transform: translateX(-10px);

            &--loading {
                @include m.flex($direction:column);
                gap: v.$regularPad;
                transform: translateX(0);
            }

            label {
                letter-spacing: 0.001em;
            }
        }
    }

    .customer-checkbox {
        @include checkbox.container-colors(
            $unmarked-stroke-color: v.$transparent,
            $unmarked-fill-color: v.$primary-200,
        );

        &.mdc-checkbox .mdc-checkbox__ripple {
            &::before,
            &::after {
                opacity: 0 !important;
            }
        }

        &:hover {
            .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
                border-color: v.$purplehover;
                border-width: 1px;
            }
        }
    }

    .cx-form-control {
        background-color: v.$softwhite !important;
    }
}
