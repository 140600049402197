@use 'styles/variables' as v;
@use 'styles/mixins' as m;
@use '~@material/button';
@use '~@material/theme';
@use '@material/textfield';
@import 'styles/animations';

.subscription__title {
    @include m.font(v.$extralarge, 500, 28px, 0.01em, v.$color-grey-1100);
    width: fit-content;
}

.subscription__desc {
    @include m.font(v.$regular, 400, v.$medium, 0.0025em, v.$color-grey-800);
    width: fit-content;
}

#subscription {
    $vip-container-width: 276px;
    $shadow: 0px 1px 40px 0px rgba(0, 0, 0, 0.06);
    
    --cds-button-height: 50px;
    --md-elevated-button-container-shape: 4px;
    --md-filled-button-container-shape: 4px;
    --md-filled-button-container-color: rgba(49, 15, 162, 1);
    --md-filled-button-hover-state-layer-color: rgba(24, 0, 136, 1);
    --md-filled-button-pressed-state-layer-color: rgba(0, 0, 111, 1);
    --md-filled-button-pressed-state-layer-opacity: 1;
    --md-filled-button-hover-state-layer-opacity: 1;
    --md-filled-button-hover-container-elevation: 0;
    --md-filled-button-label-text-size: 16px;
    --md-filled-button-label-text-line-height: 20px;
    --md-primary-tab-active-indicator-height: 0;
    --cds-primary-tab-active-container-color: rgba(255, 255, 255, 1);
    --md-primary-tab-active-pressed-state-layer-color: rgba(255, 255, 255, 1);
    --md-primary-tab-active-hover-state-layer-color: rgba(255, 255, 255, 1);

    --cds-tabs-container-background-color: rgba(247, 247, 247, 1);
    --md-primary-tab-container-color: rgba(247, 247, 247, 1);
    --md-primary-tab-pressed-state-layer-color: rgba(247, 247, 247, 1);
    --md-primary-tab-hover-state-layer-color: rgba(247, 247, 247, 1);
    
    --md-primary-tab-label-text-color: rgba(182, 182, 182, 1);
    --md-primary-tab-hover-label-text-color: rgba(182, 182, 182, 1);
    --md-primary-tab-focus-label-text-color: rgba(182, 182, 182, 1);
    --md-primary-tab-pressed-label-text-color: rgba(182, 182, 182, 1);
    --md-primary-tab-active-label-text-color: rgba(31, 31, 31, 1);
    --md-primary-tab-active-hover-label-text-color: rgba(31, 31, 31, 1);
    --md-primary-tab-active-focus-label-text-color: rgba(31, 31, 31, 1);
    --md-primary-tab-active-pressed-label-text-color: rgba(31, 31, 31, 1);

    --md-primary-tab-label-text-size: 16px;
    --md-primary-tab-label-text-weight: 400;

    @media (width >= v.$tablet) {
        --cds-button-max-width: 260px;
    }

    @media (width >= v.$desktop) {
        --cds-button-max-width: 250px;
    }

    .cart-icon {
        margin-right: 6px;
    }

    .unsubscribed-container {
        @include m.flex-center();
        padding-bottom: v.$regularPad;

        .unsubscriber-content-container {
            gap: v.$regularPad;
            display: flex;
            margin-top: 25px;

            @include m.media(v.$desktop) {
                gap: unset;
                @include m.size(100%, 300px);
                margin-top: 0;
                background: v.$white;
                box-shadow: $shadow;
            }

            @include m.media(v.$phone) {
                height: 222px !important;
            }
        }

        .chicks-vip-info-container {
            @include m.container-fluid(276px);
            outline: 1px solid v.$light-gray-border;
            border-radius: v.$thinborder;

            .legend-container {
                align-self: center;
                gap: v.$smallPad;

                .legend-item-container {
                    @extend .skeleton-lilac;
                    margin-bottom: v.$smallPadPlus;

                    &:nth-child(1) {
                        @include m.square(16px);
                    }

                    &:nth-child(2) {
                        @include m.size(214px, 34px);
                    }
                }

                @include m.media(v.$desktop) {
                    .legend-item-container {
                        &:nth-child(2) {
                            @include m.size(278px, 34px);
                        }
                    }
                }

                @include m.media(v.$phone) {
                    width: 100%;

                    .legend-item-container {
                        &:nth-child(2) {
                            flex-grow: 1;
                        }
                    }
                }
            }

            @include m.media(v.$desktop) {
                width: 100%;
                background-color: unset;
                margin-inline: v.$regularPad;
                outline: none;
            }

            @include m.media(v.$phone) {
                margin-inline: unset;
                padding: v.$regularPad;
                height: 222px !important;
                @include m.container-fluid(100%);
            }
        }
    }

    #cx-line {
        margin: 0 !important;
    }

    .text-gray {
        color: rgba(33, 37, 41, 1);
        opacity: 0.75;
    }

    .info-scrollbar {
        margin-top: v.$regularPad !important;
    }

    .main-container-subscriptions {
        padding: v.$regularPad;

        &:not(.main-container-subscriptions__subscribed) {
            border-top-left-radius: 0;
        }

        &.main-container-subscriptions__subscribed {
            .main-containers {
                gap: v.$regularPad;
            }
        }

        &.phone-container {
            margin-top: v.$bigPad;
            padding: v.$regularPad;

            & .phone-verification {
                img {
                    @include m.size(v.$extralarge, v.$extralarge);
                }

                span {
                    margin-left: v.$tinyPad;
                }
            }

            #cx-phone-input {
                max-width: 690px;

                .input-password {
                    @include textfield.outline-shape-radius(v.$thinborder);
                    border-radius: v.$thinborder;
                }

                .row {
                    margin-inline: 0;

                    .copy-text-bellow {
                        max-width: 452px;
                        @include m.font(v.$small, 400, v.$medium, 0.0025em, v.$color-grey-700, 1, true);
                        white-space: pre-line;

                        @media (width >= v.$desktop) {
                            font-size: v.$regular;
                            width: unset;
                            max-width: 856px;
                        }
                    }
                }

                .phone-number-container,
                .copy-text-bellow,
                .row > * {
                    padding: 0;
                }

                .iti,
                .phone-number-container,
                .code-verification-container {
                    max-width: 452px;
                    width: 100%;
               }

               .phone-number-container,
               .code-verification-container {
                    margin-top: v.$small;
               }

               .circle-icon {
                    right: 17px;
               }
            }
        }

        @media (width <= v.$desktop) {
            padding: 0;
            background-color: v.$transparent;
            box-shadow: none;

            &.phone-container {
                padding: 0;
                margin-top: v.$bigPad;

                #cx-phone-input {
                    max-width: 100%;
                }
            }
        }

        @media (width <= v.$phone) {
            &.phone-container {
                #cx-phone-input {
                    .iti,
                    .phone-number-container,
                    .code-verification-container {
                        max-width: unset;
                    }
                }
            }
        }
    }

    #unsubscribed {
        .subscription__top {
            margin-bottom: v.$regularPad;
        }


    }

    .subscription__tabs--container {
        transform: translateY(1px);
        
        @media (width >= v.$desktop) {
            max-width: 340px;            
        }
    }

    .save-msg {
        @extend .subscription__desc;
    }

    .subscription {
        &__header {
            font-size: 16px;
            font-weight: 400;
            line-height: 18.75px;
        }

        &__link {
            color: v.$cx-purple !important;
            cursor: pointer;
            display: inline-block;

            &:hover {
                text-decoration: underline !important;
            }
        }

        &__box-desc {
            @include m.font(inherit, inherit, inherit, inherit, inherit);

            p {
                @include m.no-spacing(true);
            }
        }

        &__section--manage {
            &.subscription__membership {
                display: grid;
                gap: v.$regularPad;

                .subscription__section--manage-options {
                    gap: v.$regularPad;
                    @include m.flex-column();

                    @media (width >= v.$desktop) {
                        @include m.flex();                        
                    }
                }
            }
        }
    }

    .main-containers {
        @include m.flex($direction:column-reverse);

        @media (width >= v.$desktop) {
            @include m.flex($direction:column);            
        }

        &__benefits {
            margin-top: v.$regularPad;

            @media (width >= v.$desktop) {
                margin-top: 0;                
            }

            .subscription__benefits--header {
                margin-bottom: v.$regularPad;
            }

            .main-containers__benefits--boxes {
                margin-top: v.$regularPad;
                @include m.grid-gap(v.$regularPad, true);

                @media (width >= v.$phone) {
                    @include m.grid-square(16px, true, 2);                    
                }
    
                @media (width >= v.$desktop) {
                    margin-top: 0;
                    @include m.grid-gap(16px, false, true , 4);
                }
            }
        }

        &__payment-method {
            border-radius: 0 0 v.$thinborder v.$thinborder;

            @media (width >= v.$desktop) {
                margin-top: v.$bigPad;
                border-radius: 0 0 v.$thinborder v.$thinborder;
                padding: 0;               
            }

            & .payment-container {
                padding: v.$regularPad;
                border: 1px solid v.$light-gray-border;
                border-radius: 0 0 v.$thinborder v.$thinborder;

                .primary-style {
                    @include button.horizontal-padding(14px);
                    @include button.height(50px);
                    border-radius: v.$thinborder !important;
                    max-width: 192px;
                    line-height: 18.75px;
                    letter-spacing: 0.15px;
                    font-size: v.$medium;
            
                    &[disabled] {
                        opacity: 30%;
                        background: v.$purple-text;
                    }
            
                    @media (width <= v.$phone) {
                        max-width: 100%;
                        font-size: v.$regular;
                    }
                }

                @media (width >= v.$desktop) {
                    padding: 0;    
                    border: none;           
                }

                .payment-container__selector {
                    margin-bottom: v.$regularPad;
                    gap: 16px;

                    & .payment-container__selector--payment-methods {
                        @include m.container-fluid(300px);                        

                        @media (width <= v.$desktop) {
                            margin-right: v.$smallPad;
                        }

                        @media (width <= v.$phone) {
                            max-width: 100%;
                            margin-right: 0;
                        }
                    }
                }

                .payment-container__button--skeleton {
                    height: 50px;
                    width: var(--cds-button-max-width);
                }

                .payment-container__message  {
                    @include m.font(v.$regular, 300, v.$medium, 0.001em, v.$color-grey-800);

                    &--link {
                        color: rgba(27, 15, 162, 1);
                    }
                }
            }
        
            &-container {
                &.payment-container {
                    padding: v.$regularPad;
                    height: fit-content;
                    @include m.container-fluid(100%);

                    & .payment-container__title {
                        margin-bottom: v.$regularPad;
                    }

                    & .payment-container__selector {
                        margin-bottom: v.$regularPad;

                        & > * {
                            flex-grow: 1;
                        }
                    }
                }

                @include m.media(v.$desktop) {
                    outline: none;

                    &.payment-container {
                        padding: v.$regularPad v.$regularPad v.$regularPad v.$tinyPad;
                    }

                    &.payment-container {
                        & .payment-container__selector {
                            margin-bottom: v.$regularPad;
                        }
                    }
                }

                @include m.media(v.$phone) {
                    &.payment-container {
                        padding: v.$regularPad;
                    }
                }
            }
        }

        &-content {
            @include m.no-spacing();
            gap: v.$regularPad;

            @media (width <= v.$desktop) {
                gap: 0;
            }

            @media (width <= v.$phone) {
                gap: v.$bigPad;
            }
        }

        @media (width <= v.$phone) {
            box-shadow: none;
        }
    }

    .subscription-divider {
        background: rgba(236, 237, 241, 1);

        &.first {
            margin: v.$smallPad 0 !important;

            @media (width <= v.$desktop) {
                margin: v.$regularPad 0 !important;
            }
        }

        &.second {
            margin: v.$medium 0 !important;

            @media (width <= v.$desktop) {
                margin: v.$smallPad 0 !important;
            }
        }
    }

    .highlight-yellow {
        background-color: v.$warning-filled-state-layer-color;
        border-radius: v.$thinborder;
        padding: 3px v.$smallPad;
        color: v.$warning-filled-label-text-color;
        font-size: v.$small;
        line-height: v.$medium;
        margin-left: 3px;
    }

    .text-center-phone {
        @media (width <= v.$phone) {
            text-align: center;
        }
    }

    .vip-container {
        height: 100%;
        min-height: 272px;

        &__title {
            background: v.$secondarycolor;
            font-weight: 700;
            font-size: v.$extralarge;
            border-radius: v.$thinborder v.$thinborder 0 0;
            height: 40px;

            h2 {
                margin: 0;
                letter-spacing: 0.19px;
                line-height: 23px !important;
            }
        }

        p.vip-container__price {
            font-weight: 500;
            line-height: 33px !important;
            letter-spacing: 0.19px !important;

            span:not(.vip-container__price--free-trial) {
                font-size: v.$huge;

                @media (width <= v.$desktop) {
                    font-size: 42px;
                }
            }

            & .vip-container__price--free-trial {
                font-size: v.$huge;

                @media (width <= v.$desktop) {
                    font-size: v.$moreplushuge;
                }
            }
        }

        &__content {
            padding: v.$regularPad v.$regularPad v.$smallPadPlus;

            p {
                line-height: 17px !important;
                letter-spacing: 0.19px !important;
                font-size: v.$small !important;
                font-weight: 400;
                color: rgba(33, 37, 41, 1);
                margin: 0;
            }

            @include m.media-range(v.$phone, v.$desktop) {
                padding: v.$bigPad 35px v.$regularPad v.$regularPad;

            }

            @include m.media(v.$phone) {
                padding: v.$smallPadPlus v.$smallPadPlus v.$regularPad;
            }
        }

        &__price {
            margin-bottom: v.$smallPad !important;
        }

        &__notice {
            margin-bottom: v.$smallPad !important;

            &--first p {
                line-height: v.$regular !important;
            }
        }

        &__item {
            @include m.flex(flex-start);
            gap: 10.44px;

            &:not(:last-child) {
                margin-bottom: v.$smallPad !important;

                @media (width <= v.$desktop) {
                    margin-bottom: v.$smallPadPlus !important;
                }
            }

            p {
                margin: 0 !important;
                text-align: left;
            }
        }

        &__checkmark {
            font-size: v.$mediumplus;
            color: v.$purple-text;
        }

        &__col {
            padding-inline: 0;
            @include m.container-fluid(276px);

            @media (width <= v.$desktop) {
                @include m.container-fluid(344px);
            }

            @media (width <= v.$tablet) {
                @include m.container-fluid(100%);

            }
        }
    }

    .purchase-flow-expandable {
        box-shadow: none !important;

        @include m.media(v.$desktop) {
            padding-inline: v.$regularPad !important;
        }

        & .mdc-expandable__header {
            padding: 0;

            &.mdc-expandable__header--closed,
            &.mdc-expandable__header--open {
                height: 64px !important;
                border-radius: 0;
            }

            .mdc-expandable__dropdown-icon {
                margin: 0 5px;
            }

            .expandable-item-arrow {
                color: v.$purple-text;
                transition: all 0.2s ease;
            }

            &.mdc-expandable__header--closed .expandable-item-arrow {
                transform: rotate(0deg);
            }

            &.mdc-expandable__header--open .expandable-item-arrow {
                transform: rotate(180deg);
            }


            &.mdc-expandable__header--focused {
                border-radius: 0 !important;
            }

            &.mdc-ripple-upgraded--background-focused::before {
                opacity: 0 !important;
            }

            &::before,
            &::after {
                background-color: v.$transparent;
            }
        }

        &.mdc-expandable--open {
            margin: 0 !important;
        }

        .mdc-expandable__content {
            padding: 0 0 v.$regularPad 0 !important;

            .drawer-subitem {
                padding: v.$smallPad v.$bigPad;
            }

            .mdc-expandable__content-container-sub .mdc-expandable__content {
                padding: 0 !important;
            }

            .item-flag {
                width: 30px;
                max-height: 20px;
                margin-right: 7px;
            }
        }

        #cx-address-input {
            .joined-input-container,
            .time-container,
            .main-container {
                margin: 0;
            }
        }
    }

    .payment-information {
        margin-top: v.$regularPad;

        @include m.media(v.$desktop) {
            margin-top: 0;
        }
    }

    .subscription__title.skeleton-loading {
        width: 140px;
    }

    .subscription__desc.skeleton-loading {
        width: 128px;
    }
    .previous-card {
        min-width: 267px;
        min-height: 111px;
        background-color: v.$palegray;
        transition: background-color 0.5s ease, border-color 0.5s ease;
        border: 1px solid v.$transparent;

        .static-width {
            width: 173px;
            margin-left: 10px;

            @media (width <= v.$desktop) {
                width: 133px;
            }

            @media (width <= v.$tablet) {
                width: 58.33%;
            }
        }

        &.selected {
            border: 1px solid v.$cx-purple;
        }

        &.expired {
            border: 1px solid v.$red;
        }

        &.expiring-soon {
            border: 1px solid v.$yellow;
        }

        .card-image {
            max-width: 45px;
            width: 42px;
        }

        .card-billing-address {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .close-icon-card {
        height: 10px;
        opacity: 0;
        transition: visibility 0.3s linear, opacity 0.3s linear;
        visibility: hidden;
        filter: brightness(0%);
        width: 10px;

        @media (v.$tablet <= width <= v.$desktop) {
            &.close-icon-card-expired {
                margin-right: 6px;
            }
        }
    }

    .previous-card:hover {
        border: 1px solid rgba(87, 98, 213, 0.4);

        .close-icon-card {
            opacity: 1;
            visibility: visible;
        }
    }

    #help-icon img {
        height: 20px;
        position: absolute;
        right: 15px;
        top: 15px;
        filter: invert(100%);
    }

    .lock-icon {
        max-height: 16px;
        max-width: 13px;
    }

    .cx-google-pay-box,
    .cx-apple-pay-box {
        min-width: 250px;
    }
}
