@import 'styles/variables';
@import 'styles/animations';

#verification {
    cds-large-button.is-active {
        --cds-option-box-flex-wrap: wrap;
    }

    .single-container {
        background: $white;
        box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        flex: 1 1 auto;
        min-height: 70px;
        height: auto;
        padding: 18px 25px 18px 29px;
        flex-flow: column;

        &.skeleton-loading {
            outline: unset;
            box-shadow: unset;
        }

        &.cursor-pointer:hover {
            outline: 1px solid $purplehover;

            .add-icon,
            .arrow-icon {
                display: block;
            }
        }

        &.update.active {
            outline: 1px solid $purple-text;

            .add-icon {
                display: block;
            }
        }

        &.update {
            .edit {
                position: absolute;
                right: 25px;

                @media (max-width: $phone) {
                    font-size: $regular;
                }
            }

            &:hover {
                .edit,
                .arrow-icon {
                    display: block;
                }
            }

            @media (max-width: $tablet) {
                .edit {
                    right: 14px;
                }
            }

            @media (max-width: $tablet) {
                .edit {
                    right: 20px;
                }
            }
        }

        .position-relative {
            .edit {
                right: 0 !important;

                &.text-close {
                    right: -3px !important;
                }
            }

            @media (width: $tablet) {
                .edit {
                    right: 5px !important;

                    &.text-close {
                        right: 2px !important;
                    }
                }
            }

            @media (max-width: ($tablet - 1)) {
                .edit,
                .edit.text-close {
                    right: unset !important;
                }
            }
        }

        &:hover {
            .checkmark {
                content: url('/static/icons/checkmark_filled.svg');
            }

            img.pending {
                content: url('/static/icons/pending_filled.svg');
            }

            .phone {
                content: url('/static/icons/phone_filled.svg');
            }

            .email {
                content: url('/static/icons/email_filled.svg');
                width: 35px;
            }

            .finger {
                content: url('/static/icons/finger_filled.svg');
                width: 35px;
            }

            .address {
                content: url('/static/icons/address_filled.svg');
            }

            .additional {
                content: url('/static/icons/additional_filled.svg');
            }
        }

        &.skeleton-loading {
            height: 70px !important;
        }
    }

    .section-header {
        font-size: $medium;
        line-height: $large;
        font-weight: 400;
    }

    .section-subtitle {
        font-size: $small;
        line-height: $regular;
    }

    .disabled {
        opacity: 20%;
        cursor: auto;
        pointer-events: none;
    }

    .input-inner-container {
        & .phone-number-container,
        & .code-verification-container,
        & .email-container {
            width: 410px;
        }

        & .copy-text-bellow {
            max-width: 660px;
            padding-right: 0;
            line-height: 14.06px;
            letter-spacing: 0.32px;
        }

        &.additional-information {
            & .file-input {
                max-width: 605px;

                @media (max-width: $desktop) {
                    max-width: 390px;
                }
            }

            &.hover-drop-zone {
                height: 110px;

                & .file-input {
                    display: none;
                }
            }

            .drop-zone-lg {
                z-index: -1;
            }
        }
    }

    .input-outer-container {
        margin-left: 0;
    }

    .mini-spinner-icon {
        position: absolute;
        right: 20px;
        top: 12.5px;
    }

    .circle-icon {
        position: absolute;
        right: 24px;
        top: 17px;
    }

    .circle-icon_close {
        color: $yellow;
        position: absolute;
        right: 275px;
        top: 17px;

        @media (max-width: $tablet) {
            right: 40px;
        }
    }

    .upload-icon {
        width: 16px;
        height: 19px;
        position: absolute;
        right: 18px;
        top: 14px;
    }

    .checkmark {
        height: 16px;
        width: 16px;
        left: 24px;
        top: 19px;
        content: url('/static/icons/checkmark_unfilled.svg');

        &:has(~ .phone) {
            left: 20px;
        }
    }

    img.pending {
        height: 16px;
        width: 16px;
        z-index: 1;
        left: 24px;
        top: 19px;
        content: url('/static/icons/pending_unfilled.svg');

        &:has(~ .phone) {
            left: 20px;
        }
    }

    .id-verification-number {
        font-size: $regular;
        font-weight: bolder;
        height: 27px;
        width: 27px;
        border-radius: 50%;
        background-color: $cx-purple;
        color: white;
    }

    .steps-wrapper {
        justify-content: start;
        align-items: center;
    }

    .id-verification-title {
        font-size: $regular;
        font-weight: bold;
    }

    .h-separator {
        width: 23px;
        height: 2px;
        background-color: $purple-text;
    }

    .veriff-btn {
        height: 50px !important;
        padding: 0 50px;
    }

    .verification-form {
        width: 410px;
    }

    .verification-footer {
        margin-bottom: 100px;

        @media (max-width: $desktop) {
            margin-top: 70px;
            margin-bottom: 215px;
        }

        @media (max-width: $phone) {
            margin-top: 30px;
            margin-bottom: 0px;
        }
    }

    .id-verification {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .add-icon,
    .edit,
    .arrow-icon {
        display: none;

        @media (max-width: $desktop) {
            display: block;
        }

        @media (max-width: $phone) {
            font-size: $regular;
        }
    }

    .arrow-icon {
        height: 30px;
        width: 30px;
        position: absolute;
        font-size: 30px;
        right: -9px;
        top: -5px;
    }

    .verified {
        color: $darkergray-medium;
        font-size: $regular;
        margin-right: 35px;
        max-width: 340px;
    }

    .phone {
        content: url('/static/icons/phone_unfilled.svg');
        margin-left: 8px;
        margin-right: 29px;

        @media (max-width: $phone) {
            margin-right: 19px;
        }
    }

    .email {
        content: url('/static/icons/email_unfilled.svg');
        margin-left: 4px;
        margin-right: 20px;

        @media (max-width: $phone) {
            margin-right: 10px;
        }
    }

    .finger {
        content: url('/static/icons/finger_unfilled.svg');
        margin-left: 4px;
        margin-right: 21px;

        @media (max-width: $phone) {
            margin-right: 11px;
        }
    }

    .address {
        content: url('/static/icons/address_unfilled.svg');
        margin-left: 4px;
        margin-right: 21px;

        @media (max-width: $phone) {
            margin-right: 11px;
        }
    }

    .additional {
        content: url('/static/icons/additional_unfilled.svg');
        margin-left: 4px;
        margin-right: 21px;

        @media (max-width: $phone) {
            margin-right: 11px;
        }
    }

    .icon-container {
        height: 35px;
    }

    .support-msg {
        font-size: $small;
    }

    .edit-container {
        right: 17px;

        & .edit:not(.text-close) {
            right: unset !important;
        }

        & .text-close {
            right: -27px !important;
        }
    }

    .custom-tooltip {
        width: max-content;
        right: -24px;
        top: 22px;
        white-space: nowrap;
        transform-origin: right top;

        .mdc-tooltip__surface {
            max-width: unset;
        }

        &::after {
            right: 6px;
            left: unset;
            border-width: 0 5px 8px 5px;
        }
    }

    .text-close {
        font-size: $extralarge;
        right: 22px !important;
    }

    @media (max-width: $desktop) {
        .edit {
            display: block;
        }

        .support-msg {
            font-size: $tiny;
        }

        .verified {
            max-width: 215px;
            font-size: $small;
        }

        .section-header {
            font-size: $regular;
        }

        .section-subtitle {
            font-size: $small;
        }
    }

    @media (max-width: $tablet) {
        .single-container {
            padding: 28px 20px;
        }

        .input-inner-container {
            margin-top: 0;
            width: 100%;
        }

        .verification-form {
            width: unset;
        }
    }

    @media (max-width: $phone) {
        cx-veriff,
        .veriff-btn {
            width: 100%;
        }

        .single-container {
            padding: 19px;
            flex-flow: column;
            justify-content: center;
        }

        .input-outer-container {
            margin-left: 0;
        }

        .input-inner-container {
            margin-top: 4px;

            & .email-container {
                width: unset;
            }
        }

        .steps-wrapper {
            justify-content: start;
            align-items: flex-start;
        }

        .id-verification {
            flex-direction: column-reverse;
        }

        .verified {
            max-width: 100px;
            margin-right: 25px;
        }
    }

    #drag-drop-additional-info {
        &.hover-drop-zone {
            #drop-zone {
                border: 1px dashed $purple-text;
                display: flex;
            }
        }

        #drop-zone {
            width: 100%;
            height: 110px;
            top: 0;
            display: none;
            box-sizing: border-box;
            border-radius: 4px;
            position: absolute;
            align-items: center;
            justify-content: center;
            background-color: rgba(49, 15, 162, 0.05) !important;
            z-index: 1;
        }
    }

    .section-icons,
    .link-styling {
        color: $darkergray;

        &:hover {
            color: $purple-text;
        }
    }
}
