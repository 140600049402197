@use 'styles/variables' as v;
@use 'styles/mixins' as m;

#subscription-manage-membership {
    --md-primary-tab-container-shape: 4px;
    --md-primary-tab-container-height: 28px;
    --md-primary-tab-label-text-size: 14px;
    --md-primary-tab-active-indicator-height: 0;
    --cds-tabs-container-background-color: rgba(231, 231, 231, 1);
    --md-primary-tab-container-color: rgba(231, 231, 231, 1);
    --md-primary-tab-pressed-state-layer-color: rgba(231, 231, 231, 1);
    --md-primary-tab-hover-state-layer-color: rgba(231, 231, 231, 1);
    --cds-primary-tab-active-container-color: rgba(49, 15, 162, 1);
    --md-primary-tab-active-pressed-state-layer-color: rgba(49, 15, 162, 1);
    --md-primary-tab-active-hover-state-layer-color: rgba(49, 15, 162, 1);
    --md-primary-tab-active-label-text-color: rgba(252, 252, 252, 1);
    --md-primary-tab-active-hover-label-text-color: rgba(252, 252, 252, 1);
    --md-primary-tab-active-focus-label-text-color: rgba(252, 252, 252, 1);
    --md-primary-tab-active-pressed-label-text-color: rgba(252, 252, 252, 1);
    --md-primary-tab-label-text-line-height: 16px;
    --md-primary-tab-label-text-color: rgba(82, 82, 82, 1);
    --md-primary-tab-hover-label-text-color: rgba(82, 82, 82, 1);
    --md-primary-tab-focus-label-text-color: rgba(82, 82, 82, 1);
    --md-primary-tab-pressed-label-text-color: rgba(82, 82, 82, 1);
    --md-primary-tab-label-text-weight: 400;

    .main-container-subscriptions {
        padding: v.$regularPad;

        @media (width <= v.$desktop) {
            max-width: unset;
            padding: 0;
            background-color: v.$transparent;
            box-shadow: none;
        }
    }
    
    cds-back-button {
        position: relative;
        z-index: 10;
    }

    .manage-membership-header {
        margin-block: v.$regularPad;

        &__title {
            font-size: v.$extralarge;
            line-height: 29px;        
            font-weight: 500;
            letter-spacing: 0.7px;
            margin-bottom: v.$tinyPad;
        }

        &__desc {
            font-size: v.$regular;
            font-weight: 400;
            line-height: 16.41px; 
            letter-spacing: 0.1px;

            .subscription__link {
                color: v.$cx-purple !important;
                cursor: pointer;
                display: inline-block;
    
                &:hover {
                    text-decoration: underline !important;
                }
            }
        }

        @media (width >= v.$desktop) {
            margin-top: v.$bigPad;
        }
    }

    .manage-membership-boxes {
        @include m.grid-gap(v.$regularPad, true);
        
        @media (width >= v.$desktop) {
            @include m.grid-square(v.$regularPad, true, 2);
        }
    }
}
