@use 'styles/variables' as v;
@use 'styles/mixins' as m;

#subscription-payment-details {    
    --md-primary-tab-container-shape: 4px;
    --md-primary-tab-container-height: 28px;
    --md-primary-tab-label-text-size: 14px;
    --md-primary-tab-active-indicator-height: 0;
    --cds-tabs-container-background-color: rgba(231, 231, 231, 1);
    --md-primary-tab-container-color: rgba(231, 231, 231, 1);
    --md-primary-tab-pressed-state-layer-color: rgba(231, 231, 231, 1);
    --md-primary-tab-hover-state-layer-color: rgba(231, 231, 231, 1);
    --cds-primary-tab-active-container-color: rgba(49, 15, 162, 1);
    --md-primary-tab-active-pressed-state-layer-color: rgba(49, 15, 162, 1);
    --md-primary-tab-active-hover-state-layer-color: rgba(49, 15, 162, 1);
    --md-primary-tab-active-label-text-color: rgba(252, 252, 252, 1);
    --md-primary-tab-active-hover-label-text-color: rgba(252, 252, 252, 1);
    --md-primary-tab-active-focus-label-text-color: rgba(252, 252, 252, 1);
    --md-primary-tab-active-pressed-label-text-color: rgba(252, 252, 252, 1);
    --md-primary-tab-label-text-line-height: 16px;
    --md-primary-tab-label-text-color: rgba(82, 82, 82, 1);
    --md-primary-tab-hover-label-text-color: rgba(82, 82, 82, 1);
    --md-primary-tab-focus-label-text-color: rgba(82, 82, 82, 1);
    --md-primary-tab-pressed-label-text-color: rgba(82, 82, 82, 1);
    --md-primary-tab-label-text-weight: 400;

    .main-container-subscriptions {
        padding: v.$regularPad;

        @media (width <= v.$desktop) {
            max-width: unset;
            padding: 0;
            background-color: v.$transparent;
            box-shadow: none;
        }
    }
    
    cds-back-button {
        position: relative;
        z-index: 10;
    }

    .change-plan-container {
        margin-bottom: v.$bigPad;

        .options-container {
            width: 168px;
        }
    }

    .payment-details-header {
        margin-top: v.$regularPad;

        &__title {
            @include m.font(v.$extralarge, 700, 29px, 0.7px);
            margin-bottom: v.$tinyPad;
        }

        &__desc {
            @include m.font(v.$regular, 300, 16.41px, 0.1px, v.$subtitle);
            margin-bottom: v.$regularPad;
        }

        @media (width >= v.$desktop) {
            margin-block: v.$bigPad v.$regularPad;
        }
    }

    .payment-details-boxes {
        display: grid;
        gap: v.$regularPad;

        &__link {
            color: v.$subtitle;
            
            &:hover {
                text-decoration: underline !important;
            }
        }
    }

    .payment-details-boxes__methods {
        cx-payment-method-selector {
            flex: 1;
        }

        .payment-selectors {
            @include m.flex-gap($gap: v.$smallPad, $direction: column);
            @include m.container-fluid();
            flex: 1;
            flex-wrap: nowrap;
    
            cx-payment-method-selector {
                width: 100%;

                .payment-method-selector:not(:hover) {
                    outline: 1px solid rgba(236, 237, 241, 1);
                }
            }

            @media (width >= v.$phone) {
                @include m.flex-gap($gap: v.$regularPad, $direction: row);
            }

            @media (width >= v.$desktop) {
                @include m.container-fluid(620px);
            }
        }                
    }

    .subscription__link {
        color: v.$cx-purple !important;
        cursor: pointer;
        display: inline-block;

        &:hover {
            text-decoration: underline !important;
        }
    }
}
