@use 'styles/variables' as v;

#subscription-cashback {
    .main-container-subscriptions {
        padding: v.$regularPad;

        @media (width <= v.$desktop) {
            max-width: unset;
            padding: 0;
            background-color: v.$transparent;
            box-shadow: none;
        }
    }

    .cashback-header {
        margin-top: v.$regularPad;

        &__title {
            font-size: v.$extralarge;
            line-height: 29px;        
            font-weight: 500;
            letter-spacing: 0.7px;
            margin-bottom: v.$tinyPad;
        }

        &__desc {
            font-size: v.$regular;
            font-weight: 400;
            line-height: 16.41px; 
            letter-spacing: 0.1px;
        }

        @media (width >= v.$desktop) {
            margin-top: v.$bigPad;
        }
    }

    #cx-order-row {
        @media (width <= v.$phone) { 
            .order-row {
                padding: v.$extra-tiny;

                .mobile-top-row {
                    padding-top: v.$smallPad;
                }

                .mobile-bottom-row {
                    padding-bottom: v.$smallPad;
                }

                .data {
                    font-size: v.$regular;
                    line-height: v.$small;
                }
            }
        }
    }

    //This should be eliminated when working on the table consistency ticket.
    #cx-table .table-headers {
        margin-right: 0;
    }
}
