@use 'variables' as v;

.skeleton {
    animation: skeletonLoading .5s linear infinite alternate;
}

.skeleton-lilac {
    animation: skeletonLilacLoading .5s linear infinite alternate;
}

@keyframes skeletonLoading {
    0% {
        background-color: v.$secondarycolor-pressed;
        opacity: 0.5;
    }

    50% {
        background-color: v.$secondarycolor-pressed;
        opacity: 0.75;
    }

    100% {
        background-color: v.$secondarycolor-pressed;
    }
}

@keyframes skeletonLilacLoading {
    0% {
        background-color: v.$secondarycolor;
        opacity: 0.5;
    }

    50% {
        background-color: v.$secondarycolor;
        opacity: 0.75;
    }

    100% {
        background-color: v.$secondarycolor;
    }
}
