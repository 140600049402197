@use '~@material/button';
@import 'styles/variables';
@import 'styles/animations';
@import 'styles/mixins';

#support {
    #cx-button .secondary:not(:disabled) {
        color: rgba(12, 42, 146, 1);
    }

    .single-container {
        background: $white;
        flex: 1 1 auto;
        height: auto;
        min-height: 332px;
        padding: 40px 20px;

        &.single-container__detail {
            @media (max-width: $tablet) {
                margin-bottom: 81px !important;
            }
        }
    }

    .skeleton-configuration {
        animation: skeletonLoading .5s linear infinite alternate;
        border-radius: $thinborder;
    }

    .skeleton-container-table {
        gap: $regularPad;

        .skeleton-container-rows {
            @extend .skeleton-configuration;
            @include skeleton-background();
            @include size(100%, $mediumPad);

            &:nth-child(1) {
                height: $mediumPad;
                margin-bottom: $regularPad;

                @media (width <= $phone) {
                    display: none;
                }
            }

            &:nth-child(2) {
                @media (width <= $phone) {
                    margin-top: $regularPad;
                }
            }

            &:nth-child(8) {
                margin-top: $regularPad;
                @include size(330px, 41px);
            }

            @include media($desktop) {
                @include size(100%, $largePad);

                &:nth-child(1) {
                    margin-bottom: $smallPad;
                    height: $regularPad;
                }

                &:nth-child(8) {
                    margin-block: $regularPad $bigPad;
                    @include size(250px, $bigPad);
                }
            }

            @include media($phone) {
                @include size(100%, 115px);
            }
        }

        @include media($desktop) {
            gap: 0.625rem;
        }
    }

    .return {
        transform: translateX(-10px);
        margin-bottom: 30px;
    }

    .section-title {
        font-size: $extralarge;
        color: $black;
        font-weight: 500;
        margin-bottom: 10px;

        &.skeleton-loading {
            height: 36px;
            width: 180px;

            @media (width <= $tablet) {
                width: 150px;
            }
        }
    }

    .inner-title {
        font-size: $extralarge;
        font-weight: 500;
        margin-bottom: 30px;
    }

    .inner-subtitle {
        font-size: $medium;
        margin-bottom: 30px;
    }

    .section-subtitle {
        font-size: $medium;
        margin-bottom: 30px;

        &.skeleton-loading {
            height: 24px;
            width: 300px;

            @media (width <= $tablet) {
                width: 260px;
            }
        }
    }

    .ticket-btn {
        height: 50px !important;
        width: 174px;
    }

    .new-ticket-btn {
        width: 218px !important;
        padding: 0 24px !important;
        font-size: $medium !important;
    }

    .new-ticket-btn-list {
        font-size: $medium;
    }

    .btn-container {
        width: auto;
        padding-top: 20px;

        & .ticket-btn {
            letter-spacing: 0.14px;

            &.send-btn {
                width: 167px;

                &.disabled {
                    background: $purple-disabled !important;
                    opacity: 35%;
                    @include button.container-fill-color($transparent);
                    @include button.ink-color($darkergray);
                }
            }
        }
    }

    .btn-container-list {
        width: unset;
    }

    .demo-container {
        width: 160px;
    }

    .pagination a {
        background-color: $white !important;
    }

    mdc-data-table {
        border: none;
    }

    thead {
        th {
            color: $black;
            font-size: $medium;
            font-weight: 700;
            padding-left: 0;
            border: none !important;
        }
    }

    tbody {
        font-size: $regular;

        td {
            padding: 15px 5px;
            border-color: $divisor-gray !important;
        }

        tr {
            &:hover {
                background-color: rgba(167, 135, 215, 0.1) !important;
                cursor: pointer;
            }
        }
    }

    .support-header {
        width: auto;
    }

    .support-header-create {
        margin-bottom: 10px;
    }

    .pseudo-table #cx-status-tag {
        font-size: $tiny;
        width: 110px;
        margin-left: -10px;

        .tag {
            height: 30px !important;
        }
    }

    .sort-asc {
        margin-top: -55%;
    }

    .sort-desc {
        margin-top: -60%;
    }

    .mdc-data-table__sort-icon-button {
        border: none;
        background-color: $transparent;
        height: 15px;
        width: 17px;
        margin-left: 0;
        color: $black;
    }

    .text-underline:hover {
        text-decoration: underline !important;
        color: rgba(63, 4, 168, 1);
    }

    @media (max-width: $tablet) {
        .single-container {
            padding: 20px;
            min-height: 284px;

            &:not(&.mb-4) {
                margin-bottom: -10px;
            }
        }

        .section-title {
            font-size: $large;
        }

        .section-subtitle {
            font-size: $regular;
        }

        .inner-title {
            font-size: $large;
            margin-bottom: 6px;
        }

        .inner-subtitle {
            font-size: $regular;
            margin-bottom: 22px;
        }

        .return {
            margin-bottom: 20px;
        }

        .mdc-data-table__sort-icon-button {
            opacity: 1;
        }
    }

    @media (max-width: $phone) {
        .single-container {
            padding: 15px;
            min-height: 281px;

            &:not(&.mb-4) {
                margin: 0 10px;
                min-height: 482px;
            }
        }

        .ticket-btn {
            width: 100% !important;
            font-size: $regular;
            padding: 0 19px;
        }

        .btn-container {
            width: 100%;

            & .ticket-btn {
                min-width: 319px;
                margin-left: -7px;
            }
        }

        .btn-container-list {
            width: 100%;
        }

        .demo-container {
            width: 50%;
        }

        .support-header {
            width: 100%;
            border-bottom: 1px #e4e4e470 solid;
            padding-bottom: 20px;
            margin-bottom: 20px;

            &.support-header-sk {
                border-bottom: none !important;
                margin-bottom:$smallPad !important;
            }
        }

        .inner-subtitle {
            margin-bottom: 30px;
        }

        .section-subtitle-list {
            margin-bottom: 0 !important;
        }

        .return {
            margin-bottom: 12px;
        }

        .new-ticket-btn {
            max-width: unset !important;
        }

        .ticket-btn {
            max-width: unset !important;
        }

        .new-ticket-btn-list {
            font-size: $regular !important;
            padding: 0 12px !important;
            width: 100% !important;
        }

        .mdc-data-table__sort-icon-button {
            opacity: 1;
        }
    }
}
